// import '../css/app.scss'
// let's not do this. avoids flash of unstyled content.
// see the head tag for how we are loading this css

import './components/new-navigation.js';
import './components/search.js';
import './components/sticky.js';

// import './components/nav-widget.js';

/// Oembed, embedly, video, etc.
document.querySelectorAll( 'oembed[url]' ).forEach( element => {
    // Create the <a href="..." class="embedly-card"></a> element that Embedly uses
    // to discover the media.
    const anchor = document.createElement( 'a' );

    anchor.setAttribute( 'href', element.getAttribute( 'url' ) );
    anchor.className = 'embedly-card';

    element.appendChild( anchor );

    embedly("defaults", {
        cards: {
          width: 980,
          align: 'left',
          chrome: 0
        }
      });
} );

if (document.getElementById('post-a-job')) {
    import("./components/ckeditor-mdhs.js");
}

if (document.getElementById('scholarships-app')) {
    import("./components/scholarships.js");
}


if (document.getElementById('get-list')) {
    // test
    var testButton = document.getElementById('get-list');
    // click
    testButton.addEventListener('click', function() {
        // fetch
        fetch('/actions/_mad-utilities/chimp/get-lists')
            .then(data => {
                console.log(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    });
}

if (document.getElementById('scholarships')) {
    
    var select = document.getElementById('scholarships');
    select.addEventListener('change', function() {
            var url = select.value;
            window.location.href = url;
    });
}

// Mailchimp form submission
if (document.getElementById('chimp')) {
    const form = document.getElementById('chimp');

    form.addEventListener('submit', function (e) {
        e.preventDefault();

        // get the form data
        var formData = new FormData(form);

        // serialize it
        var object = {};
        formData.forEach(function(value, key){
            object[key] = value;
        });
        var data = JSON.stringify(object);

        // build the request and send it
        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/actions/_mad-utilities/chimp/subscribe-to-list', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(data);

        // do something with the response
        xhr.onload = function () {
            console.log( JSON.parse(xhr.responseText) );

            // clear the input
            var input = document.getElementById('chimp-email');
            input.value = '';

            // show chimp success
            var chimpSuccess = document.getElementById('chimp-success');
            // remove the hidden class
            chimpSuccess.classList.remove('hidden');
            // after 15 seconds add the hidden class
            setTimeout(function() {
                chimpSuccess.classList.add('hidden');
            }, 15000);
        };

    });

}


// Mailchimp form submission
if (document.getElementById('chimp-c4npr')) {
    const form = document.getElementById('chimp-c4npr');

    form.addEventListener('submit', function (e) {
        e.preventDefault();

        // get the form data
        var formData = new FormData(form);

        // serialize it
        var object = {};
        formData.forEach(function(value, key){
            object[key] = value;
            console.log(key);
            console.log(value);
        });
        var data = JSON.stringify(object);
        console.log('chimp-c4npr');
        console.log(data);

        // build the request and send it
        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/actions/_mad-utilities/chimp/subscribe-to-list', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(data);

        // do something with the response
        xhr.onload = function () {
            console.log( JSON.parse(xhr.responseText) );

            // clear the input
            var input = document.getElementById('chimp-email-c4npr');
            input.value = '';

            // show chimp success
            var chimpSuccess = document.getElementById('chimp-success-c4npr');
            // remove the hidden class
            chimpSuccess.classList.remove('hidden');
            // after 15 seconds add the hidden class
            setTimeout(function() {
                chimpSuccess.classList.add('hidden');
            }, 15000);
        };

    });
}

// Mailchimp form submission
if (document.getElementById('chimp-c4npr-segundo')) {
    const form = document.getElementById('chimp-c4npr-segundo');

    form.addEventListener('submit', function (e) {
        e.preventDefault();

        // get the form data
        var formData = new FormData(form);

        // serialize it
        var object = {};
        formData.forEach(function(value, key){
            object[key] = value;
        });
        var data = JSON.stringify(object);
        console.log(data);

        // build the request and send it
        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/actions/_mad-utilities/chimp/subscribe-to-list', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(data);

        // do something with the response
        xhr.onload = function () {
            console.log( JSON.parse(xhr.responseText) );

            // clear the input
            var input = document.getElementById('chimp-email-c4npr-segundo');
            input.value = '';

            // show chimp success
            var chimpSuccess = document.getElementById('chimp-success-c4npr-segundo');
            // remove the hidden class
            chimpSuccess.classList.remove('hidden');
            // after 15 seconds add the hidden class
            setTimeout(function() {
                chimpSuccess.classList.add('hidden');
            }, 15000);
        };

    });
}

// batch
if (document.getElementById('blast')) {
    // do something
    var blastButton = document.getElementById('blast');
    // click
    blastButton.addEventListener('click', function(event) {
        event.preventDefault();

        var data = {
            foo: "bar"
        };
    
        var tokenName = window.csrfTokenName;
        data[ tokenName ] = window.csrfTokenValue;
        
        // build the request and send it
        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/actions/_mad-utilities/postmark/do-something', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(data));

        // do something with the response
        xhr.onload = function () {
            console.log( xhr.responseText );
        };


    });

}

// on document load
document.addEventListener('DOMContentLoaded', function() {

    console.log('document loaded');

});







